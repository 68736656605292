import { useLanguageContext } from 'context/LanguageProvider';
import './style.css';
import { siteConfiguration } from 'siteConfig';

const Activity = () => {
	const { activityTitle, activities } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	const titleArray = activityTitle[currentLanguage].split('of');
	const firstWord = titleArray.shift();
	const remainingWords = titleArray.join(' ');
	return (
		<section className="activity-section">
			<h2>
				{`${firstWord} of `}
				<br /> {remainingWords}
			</h2>
			<div className="activities">
				{activities.map((activity, id) => (
					<div className="activity" key={id}>
						<img
							src={activity.activityPhoto}
							alt={activity.activityName[currentLanguage]}
						/>
						<h3>{activity.activityName[currentLanguage]}</h3>
						<p>{activity.activityContent[currentLanguage]}</p>
					</div>
				))}
			</div>
		</section>
	);
};

export default Activity;
/*
<div className='section-title'>
                {activityTitle}
            </div>
            <div className='activities'>
            {activities.map((activity, id) => (
                <div className='actvity' key={id}>
                    <img src={activity.activityPhoto} alt={activity.activityName}/>
                    <h1>{activity.activityName}</h1>
                    <p>{activity.activityContent}</p>
                </div>
            ))}
            </div>
*/
