import Button from 'components/Button/Button';
import './style.css';

const Ticket = ({
	ticketName,
	ticketUSDPrice,
	ticketDescription,
	text,
	route,
}) => {
	return (
		<div className="plancard">
			<div className="planHeader">
				<h3 className="planTitle">{ticketName}</h3>
				<div className="planPriceBox">
					<h2 className="planPrice">{ticketUSDPrice} $</h2>
					<h3 className="planDescription">{ticketDescription}</h3>
				</div>
			</div>
			<Button
				text={text}
				route={route}
				showArrow={true}
				arrowColor="#fff"
				isURL={true}
			/>
		</div>
	);
};

export default Ticket;
