import Header from 'components/Header/Header';
import MarkdownLoader from 'components/MarkdownLoader';
import PageHeader from 'components/PageHeader/PageHeader';
import { useLanguageContext } from 'context/LanguageProvider';
import SEO from 'pages/SEO';
import React from 'react';
import { siteConfiguration } from 'siteConfig';

const SponsorShipPage = () => {
	const { Sponsor } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<>
			<SEO
				title={'Sponsor'}
				url={'/sponsor'}
				description={'Support The State of the Map Africa (SotM Africa) '}
			/>
			<Header />
			<div className="aboutpage-section">
				<PageHeader
					title={Sponsor.title[currentLanguage]}
					picture={Sponsor.picture}
				/>

				<MarkdownLoader page={'sponsor'} />
			</div>
		</>
	);
};

export default SponsorShipPage;
