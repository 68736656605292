export default function RedirectIcon({ fillColor }) {
	return (
		<svg
			width="14"
			height="13"
			viewBox="0 0 14 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.0044 3.414L2.3974 12.021L0.983398 10.607L9.5894 2H2.0044V0H13.0044V11H11.0044V3.414Z"
				fill={fillColor}
			/>
		</svg>
	);
}
