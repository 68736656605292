import { useState, useEffect } from 'react';
import Navbar from 'components/NavBar/Navbar';

import './style.css';
import { siteConfiguration } from 'siteConfig';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { ReactComponent as LogoWhite } from 'assets/img/logoWhite.svg';
import { ReactComponent as MenuIcon } from 'assets/menu.svg';
import { ReactComponent as MenuIconWhite } from 'assets/menu-white.svg';
import { ReactComponent as CloseIcon } from 'assets/closeIcon.svg';
import Button from 'components/Button/Button';
import Language from 'components/Language/Language';
import { useLanguageContext } from 'context/LanguageProvider';

const Header = ({ activateWhite }) => {
	const { navigationButtons } = siteConfiguration;
	const [showNavbar, setShowNavbar] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const { currentLanguage } = useLanguageContext();
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 10) {
				setIsScrolled(true);
			} else {
				setIsScrolled(false);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<header className={`${isScrolled && 'header-scroll'} header-container `}>
			<div className="header-wrapper wrapper">
				{/** Logo */}
				<div>
					<a
						href="/"
						onClick={() => setShowNavbar(false)}
						className="logo-container"
					>
						{activateWhite && isScrolled === false ? (
							<LogoWhite className="logo" />
						) : isScrolled && activateWhite ? (
							<Logo className="logo" />
						) : (
							<Logo className="logo" />
						)}
					</a>
				</div>

				<div
					className={`${
						showNavbar ? 'navbar-animate ' : 'navbar-animate-close'
					}  navigation-wrapper activate-navbar`}
				>
					{/** The Nav Links*/}
					<Navbar
						whiteColor={activateWhite}
						scrolledStyling={isScrolled}
						closeNavBar={() => setShowNavbar(false)}
					/>

					{/** Language switcher and close icon on mobile */}
					<div className="language-container">
						{showNavbar && (
							<div className="language-type ">
								<Language
									whiteColor={activateWhite}
									activateScrollStyling={isScrolled}
								/>
							</div>
						)}

						{showNavbar ? (
							<CloseIcon onClick={() => setShowNavbar(!showNavbar)} />
						) : null}
					</div>

					{/** Language switcher and CTA buttons  */}
					<div
						className={`button-container ${
							activateWhite ? 'active-whitebutton' : null
						} `}
					>
						{showNavbar ? null : (
							<div className={`language-type`}>
								<Language
									whiteColor={activateWhite}
									activateScrollStyling={isScrolled}
								/>
							</div>
						)}
						{navigationButtons
							.filter((button) => button.isActive === true)
							.map((navButton, index) => {
								return navButton.isActive ? (
									<Button
										text={navButton.text[currentLanguage]}
										showArrow={navButton.showArrowIcon}
										arrowColor={navButton.arrowColor}
										route={navButton.route}
										key={`nav-${index}`}
										isURL
									/>
								) : null;
							})}
					</div>
				</div>

				{/** Nav menu Hamburger */}
				<div
					className="mobile-container"
					onClick={() => setShowNavbar(!showNavbar)}
				>
					{activateWhite && isScrolled === false ? (
						<MenuIconWhite />
					) : isScrolled && activateWhite ? (
						<MenuIcon />
					) : (
						<MenuIcon />
					)}
				</div>
			</div>
		</header>
	);
};

export default Header;
