import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './style.css';

import { useLanguageContext } from 'context/LanguageProvider';
import { siteConfiguration } from 'siteConfig';
export default function MarkdownLoader({ page }) {
	let [content, setContent] = useState(null);
	const { currentLanguage } = useLanguageContext();
	useEffect(() => {
		if (!currentLanguage) return;
		if (!page) return;
		const filePath = getFilePath(currentLanguage, page);
		fetch(filePath)
			.then((res) => res.text())
			.then((md) => {
				setContent(md);
			});
	}, [currentLanguage, page]);

	if (!content) return null;
	return (
		<ReactMarkdown
			children={content}
			className="markdown-content "
			skipHtml={false}
		/>
	);
}

const getFilePath = (currentLanguage, page) => {
	const { contentfilePathMapping } = siteConfiguration;
	return contentfilePathMapping[currentLanguage][page];
};
