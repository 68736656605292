import { siteConfiguration } from 'siteConfig';
import './style.css';
import Button from 'components/Button/Button';
import { useLanguageContext } from 'context/LanguageProvider';

const About = () => {
	const { aboutTitle, aboutContent, aboutButton } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	const titleArray = aboutTitle[currentLanguage].split(' ');
	const firstWord = titleArray.shift();
	const remainingWords = titleArray.join(' ');

	return (
		<section className="about">
			<h2 className="about-title">
				{firstWord}
				<br />
				{remainingWords}
			</h2>
			<div className="about-content">
				{aboutContent[currentLanguage].split('\n').map((paragraph, index) => (
					<p key={index}>{paragraph}</p>
				))}
				<Button
					text={aboutButton.text[currentLanguage]}
					route={aboutButton.route}
					showArrow={aboutButton.showArrowIcon}
					arrowColor={aboutButton.arrowColor}
				/>
			</div>
		</section>
	);
};

export default About;
