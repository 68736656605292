import Header from 'components/Header/Header';
import './style.css';

import { siteConfiguration } from 'siteConfig';
import React from 'react';

import PageHeader from 'components/PageHeader/PageHeader';
import MarkdownLoader from 'components/MarkdownLoader';
import SEO from 'pages/SEO';
import { useLanguageContext } from 'context/LanguageProvider';

const About = () => {
	const { About } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<>
			<SEO title={'About Us'} url={'about'} />
			<Header />
			<div className="aboutpage-section">
				<PageHeader
					title={About.title[currentLanguage]}
					picture={About.picture}
				/>

				<MarkdownLoader page={'about'} />
			</div>
		</>
	);
};

export default About;
