import conference from './assets/img/conference.png';
import community from './assets/img/community.png';
import venue1 from './assets/img/venue1.jpg';
import venue2 from './assets/img/venue2.jpg';
import maskimage from './assets/img/mask-group.png';
import workshop from './assets/img/workshop.png';
import participants from './assets/img/participants.jpg';
import yaoundeCover from './assets/img/venue1.jpg';

//page contents
import AboutMdEn from 'contents/about/about.md';
import SponsorEn from 'contents/sponsor/sponsor.md';
import CallsEn from 'contents/calls/calls.md';
import CodeOfConductEn from 'contents/code-of-conduct.md';
import AboutMdFr from 'contents/about/about_fr.md';
import SponsorFr from 'contents/sponsor/sponsor_fr.md';
import CallsFr from 'contents/calls/calls_fr.md';
import CodeOfConductFr from 'contents/code-of-conduct_fr.md';
import VenueFr from 'contents/venue/attending_fr.md';
import VenueEn from 'contents/venue/attending.md';

//sponsors
import OMDTZLogo from './assets/sponsors/Original_OMDTZ_Logo.png';
import GIZlogo from './assets/sponsors/tumi_logo_rgb_brightbg_claim_2l.png';
import MetaLogo from './assets/sponsors/Meta_lockup_positive primary_RGB.png';
import HOTLogo from './assets/sponsors/HOT_logo_with_text_White_Circle.png';
import WRILogo from './assets/sponsors/WRI.png';
import YouthMappersLogo from './assets/sponsors/youthmappers.png';
import TomTomLogo from './assets/sponsors/TomTom.png';
import AFDLogo from './assets/sponsors/AFD-Logo.png';
import DTALogo from './assets/sponsors/DT4A_Logo.png';
import HOTESAHub from './assets/sponsors/HOT_OMH_ESA_English_Logo_COLOUR_with_tagline.png';
import HOTWNA from './assets/sponsors/HOT_OMH_WNA_English_Logo_COLOUR_with_tagline.png';
import ExpertiseFranceLogo from './assets/sponsors/Ef-logo-2020.png'
//blogs
import Blog2023VenueAnnouncement from 'contents/blogs/2023-venue-announcement.md';
import Blog2023VenueAnnouncementFr from 'contents/blogs/2023-venue-announcement_fr.md';

export const siteConfiguration = {
	//Conference Information, Landing Page/Hero

	conferenceYear: {
		en: 'SotM Africa 2023',
		fr: 'SotM Afrique 2023',
	},
	conferenceTheme: {
		en: 'Open Mapping as a support tool for Local Development in Africa',
		fr: 'La cartographie ouverte comme outil de développement local en Afrique',
	},
	heroBackgroundImage: venue2,
	conferenceDate: {
		en: '30th Nov. - 2nd Dec., 2023',
		fr: '30 Nov. - 02 Déc  2023',
	},
	conferenceLocation: 'Yaounde, Cameroon',

	//About

	aboutTitle: {
		en: 'About SotM Africa',
		fr: 'À propos de SotM Africa',
	},
	aboutContent: {
		en: `The State of the Map Africa (SotM Africa) is a bi-annual regional conference that celebrates the culture of open mapping, open data, GIS and its impact across Africa. The first SotM Africa conference was hosted by the OSM community in Uganda in 2017.

  This year’s conference in Yaounde, Cameroon will continue to build on the strategy envisioned for OpenStreetMap in Africa as a renewed, strong, and growing network, and as part of the global OpenStreetMap and Open GIS movement.
  `,
		fr: `La conférence State of the Map Africa (SotM Africa) est une conférence régionale organisée tous les deux ans qui se consacre à la mise en valeur de la "cartographie ouverte", des "données ouvertes", des SIG et de leur impact à travers l'Afrique. La première conférence SotM Africa a été organisée par la communauté OSM en Ouganda en 2017.

	La conférence de cette année se tiendra à Yaoundé, au Cameroun, et continuera à s'appuyer sur la stratégie envisagée pour OpenStreetMap en Afrique en tant que réseau renouvelé, fort et en pleine croissance, et en tant que partie du mouvement mondial OpenStreetMap et de celui des SIG ouverts.`,
	},
	aboutButton: {
		text: {
			en: 'Learn more',
			fr: 'En savoir plus',
		},
		showArrowIcon: true,
		route: '/about',
		arrowColor: '#fff',
	},

	//Registration
	registrationTitle: {
		en: `Registration`,
		fr: `Inscription`,
	},
	registrationContent: {
		en: `Early bird tickets are available till the 20th of August at 75 USD for participants from Africa and 150 USD for participants from the rest of the World. After that, the tickets will go for 100 USD for Africa Participants and 250 USD for the rest of the world. We also have a corporate/business ticket going for 350 USD.`,
		fr: `Des billets à tarif réduit sont disponibles jusqu'au 20 aout à 75 USD pour les participants d'Afrique et 150 USD pour les participants du monde entier.`,
	},
	ticketTitle: {
		en: `Ticket`,
		fr: `Ticket`,
	},
	earlyBirdEndDate: {
		en: `🔥Early bird ends: 20 August, 2023`,
		fr: `🔥Fin des inscriptions anticipées : 20 août 2023.`,
	},

	//Navigation

	navigationLinks: [
		{
			text: {
				en: 'About',
				fr: 'A propos',
			},
			route: '/about',
			isActive: true,
		},
		{
			text: {
				en: 'Sponsor',
				fr: 'Sponsor',
			},
			route: '/sponsor',
			isActive: true,
		},
		{
			text: { en: 'Programme', fr: 'Programme' },
			route: '/programme',
			isActive: true,
		},
		{
			text: { en: 'Blog', fr: 'Blog' },
			route: '/blog',
			isActive: true,
		},

	],
	navigationButtons: [
		{
			text: {
				en: 'Register',
				fr: 'Inscription',
			},
			route:
				'https://www.eventbrite.com/e/state-of-the-map-africa-2023-tickets-657695694527',
			isActive: true,
			showArrowIcon: true,
			arrowColor: '#fff',
		},
	],
	heroButtons: [

		{
			text: {
				en: 'Become a sponsor',
				fr: 'Devenir sponsor',
			},
			route: '/sponsor',
			isActive: true,
			showArrowIcon: false,
			arrowColor: '#fff',
			isURL: false
		},
		{
			text: {
				en: 'Register',
				fr: 'Registre',
			},
			route: 'https://www.eventbrite.com/e/state-of-the-map-africa-2023-tickets-657695694527',
			isActive: true,
			showArrowIcon: true,
			arrowColor: '#fff',
			isURL: true,
		},
	],
	// Activities
	activityTitle: {
		en: `SotM Africa 2023 will be a 3 days of learning, sharing and fun!`,
		fr: `SotM Afrique 2023 sera une expérience d'apprentissage, de partage et d'amusement de 3 jours!`,
	},

	activities: [
		{
			activityPhoto: `${conference}`,
			activityName: {
				en: 'Conference',
				fr: 'Conférence',
			},
			activityContent: {
				en: `SOTM Africa will bring together participants from around the world to discuss challenges, learn, and celebrate many years of mapping together.`,
				fr: `SOTM Africa regroupera des participants du monde entier pour discuter des défis, apprendre et célébrer de nombreuses années de cartographie ensemble.`,
			},
		},
		{
			activityPhoto: `${workshop}`,
			activityName: {
				en: 'Workshops',
				fr: 'Ateliers',
			},
			activityContent: {
				en: `Featuring full-day of workshops, hands-on experience coupled with learning and sharing!`,
				fr: `Au programme, des ateliers tout au long de la journée, une expérience pratique associée à l'apprentissage et au partage !`,
			},
		},
		{
			activityPhoto: `${community}`,
			activityName: {
				en: 'Community',
				fr: 'Communauté',
			},
			activityContent: {
				en: `The community will meet and have fun!`,
				fr: `Toute la communauté se réunira et prendra du plaisir !`,
			},
		},
	],
	// Venue
	venueTitle: {
		en: 'Venue',
		fr: 'Lieu',
	},
	venueContent: {
		en: 'The conference is scheduled to be hosted at United Hotel, Mbankomo, Cameroon. The venue is located in the heart of the city of Yaounde with an easy access to all the districts of the city. For Visa information, accommodation and more, please click the button below.',
		fr: "La conférence se tiendra à l'hôtel Hôtel United, Mbankomo, Cameroun. Le lieu est situé au cœur de la ville de Yaoundé avec un accès facile à tous les quartiers de la ville. Pour plus d'informations sur les visas, l'hébergement et plus encore, veuillez cliquer sur le bouton ci-dessous.",
	},
	venueButton: {
		text: {
			en: 'Explore Yaounde',
			fr: 'Visiter Yaounde',
		},
		showArrowIcon: true,
		route: '/venue',
		arrowColor: '#fff',
	},
	venuePhotos: [
		{
			title: 'Yaounde Mall',
			path: `${venue1}`,
		},
		{
			title: 'Yaounde Mall',
			path: `${venue2}`,
		},
	],
	//Pricing

	conferenceTickets: [
		{
			ticketName: {
				en: 'Africa',
				fr: 'Africa',
			},
			ticketUSDPrice: '75',
			ticketDescription: {
				en: 'Participants from Africa',
				fr: "Participants d'Afrique",
			},
			ticketBtnText: {
				en: 'Buy Ticket',
				fr: 'Acheter un billet',
			},
			ticketLink:
				'https://www.eventbrite.com/e/state-of-the-map-africa-2023-tickets-657695694527',
		},
		{
			ticketName: {
				en: 'Global',
				fr: 'Global',
			},
			ticketUSDPrice: '150',

			ticketDescription: {
				en: 'Participants from other continents',
				fr: 'Participants from other continents',
			},
			ticketBtnText: {
				en: 'Buy Ticket',
				fr: 'Acheter un billet',
			},

			ticketLink:
				'https://www.eventbrite.com/e/state-of-the-map-africa-2023-tickets-657695694527',
		},
		{
			ticketName: {
				en: 'Business',
				fr: 'Business',
			},
			ticketUSDPrice: '350',

			ticketDescription: {
				en: 'For Organizations',
				fr: 'For Organizations',
			},
			ticketBtnText: {
				en: 'Buy Ticket',
				fr: 'Acheter un billet',
			},

			ticketLink:
				'https://www.eventbrite.com/e/state-of-the-map-africa-2023-tickets-657695694527',
		},
	],

	// Constant website marketing
	WebsiteMarketing: {
		marketingContent: {
			en: 'A conference for OpenStreetMap enthusiasts from all over Africa.',
			fr: "Une conférence pour les passionnés d'OpenStreetMap de toute l'Afrique.",
		},
		picture: maskimage,
	},

	//Map
	Map: {
		LatLng: [3.7867014, 11.4027244],
		location: 'United Hotel, Mbankomo, Cameroon',
		city: 'Yaounde',
		route: '/venue',
	},

	//Subscribe

	Subscribe: {
		info: {
			en: 'Stay updated on everything SotM Africa',
			fr: 'Restez au courant de toutes les activités de SotM Africa',
		},
		link: 'https://eepurl.com/itbD7k',
		btnName: {
			en: 'Subscribe',
			fr: "S'abonner",
		},
	},

	//language
	Languages: [
		{
			name: 'English',
			symbol: 'en',
		},
		{
			name: 'French',
			symbol: 'fr',
		},
	],

	//Sponsor

	AllSponsor: [
		{
			package: {
				en: 'Platinum',
				fr: 'Platine',
			},
			color: '#F0900A',
			sponsorsInfo: [
				{
					name: {
						en: 'Humanitarian OpenStreetMap Team',
						fr: 'Humanitarian OpenStreetMap Team',
					},
					logo: HOTLogo,
					url: 'https://hotosm.org/',
				},
				{
					name: {
						en: 'HOT Open Mapping Hub WNA',
						fr: 'HOT Open Mapping Hub WNA',
					},
					logo: HOTWNA,
					url: 'https://www.hotosm.org/hubs/open-mapping-hub-west-and-northern-africa',
				},
				{
					name: {
						en: 'HOT Open Mapping Hub ESA',
						fr: 'HOT Open Mapping Hub ESA',
					},
					logo: HOTESAHub,
					url: 'https://www.hotosm.org/hubs/open-mapping-hub-eastern-and-southern-africa.html',
				},
				{
					name: {
						en: 'GIZ - TUMI',
						fr: 'GIZ - TUMI',
					},
					logo: GIZlogo,
					url: 'https://transformative-mobility.org/',
				},

				{
					name: {
						en: 'WRI Africa',
						fr: 'WRI Africa',
					},
					logo: WRILogo,
					url: 'https://www.wri.org/wri-africa',
				},

				{
					name: {
						en: 'Digital Transport for Africa',
						fr: 'Digital Transport for Africa',
					},
					logo: DTALogo,
					url: 'https://digitaltransport4africa.org/',
				},

				{
					name: {
						en: 'French Development Agency (AFD)',
						fr: 'French Development Agency (AFD)',
					},
					logo: AFDLogo,
					url: 'https://www.afd.fr/fr',
				},
			],
		},
		{
			package: {
				en: 'Gold',
				fr: 'Or',
			},
			color: '#FFD700',
			sponsorsInfo: [
				{
					name: {
						en: 'OpenMap Development Tanzania',
						fr: 'OpenMap Development Tanzania',
					},
					logo: OMDTZLogo,
					url: 'https://www.omdtz.or.tz',
				},
				{
					name: {
						en: 'YouthMappers',
						fr: 'YouthMappers',
					},
					logo: YouthMappersLogo,
					url: 'https://www.youthmappers.org/',
				},
			],
		},
		{
			package: {
				en: 'Silver',
				fr: 'Argent',
			},
			color: '#C0C0C0',
			sponsorsInfo: [
				{
					name: {
						en: 'Meta',
						fr: 'Meta',
					},
					logo: MetaLogo,
					url: 'https://www.mapillary.com/',
				},
				{
					name: {
						en: 'Expertise France',
						fr: 'Expertise France',
					},
					logo: ExpertiseFranceLogo,
					url: 'https://fr.m.wikipedia.org/wiki/Expertise_France',
				},
			],
		},

		{
			package: {
				en: 'Bronze',
				fr: 'Bronze',
			},
			color: '#CD7F32',
			sponsorsInfo: [
				{
					name: {
						en: 'Tom Tom',
						fr: 'Tom Tom',
					},
					logo: TomTomLogo,
					url: 'https://www.tomtom.com/',
				},
			],
		},
	],

	// Sponsorship Prospectus
	sponsorProspectusText: {
		en: 'Your support will help us to increase diversity within the OSM community and improve OSM contribution and use within the continent.',
		fr: "Votre appui nous aidera à accroître la diversité au sein de la communauté OSM et à améliorer la contribution et l'utilisation d'OSM sur le continent.",
	},

	//Footer

	footer: [
		{
			title: {
				en: 'SotM Africa',
				fr: 'SotM Afrique',
			},
			isActive: true,
			links: [
				{
					text: {
						en: 'About',
						fr: 'A propos',
					},
					link: '/about',
					isURL: false,
				},
				{
					text: { en: 'Blog', fr: 'Blog' },
					link: '/blog',
					isURL: false,
				},
				{
					text: { en: 'Sponsor', fr: 'Sponsor' },
					link: '/Sponsor',
					isURL: false,
				},
				{
					text: { en: 'Programme', fr: 'Programme' },
					link: '/programme',
					isURL: false,
				},
			],
		},
		{
			title: {
				en: 'Quick Links',
				fr: 'Liens utiles',
			},
			isActive: true,
			links: [
				// {
				// 	text: 'Get Ticket',
				// 	link: '',
				// 	isURL: false,
				// },
				{
					text: {
						en: 'Call for Sponsor',
						fr: 'Appel à sponsor',
					},
					link: '/sponsor',
					isURL: false,
				},
				{
					text: {
						en: 'Call for Speakers',
						fr: 'Appel aux intervenants',
					},
					link: '/calls',
					isURL: false,
				},
				{
					text: {
						en: 'Call for Scholarship',
						fr: 'Demande de bourse',
					},
					link: 'https://forms.gle/w9NcUNYyyoieo37o8',
					isURL: true,
				},
				{
					text: {
						en: 'Explore Yaounde',
						fr: 'Visiter Yaounde',
					},
					link: '/venue',
					isURL: false,
				},
				// {
				// 	text: 'Travel Grant',
				// 	link: '',
				// 	isURL: false,
				// },
			],
		},
		{
			title: {
				en: 'Follow us',
				fr: 'Suivez-nous',
			},
			isActive: true,
			links: [
				{
					text: {
						en: 'Twitter',
						fr: 'Twitter',
					},
					link: 'https://twitter.com/sotmafrica',
					isURL: true,
				},
				{
					text: { en: 'Facebook', fr: 'Facebook' },
					link: 'https://www.facebook.com/sotmafrica/?_rdc=1&_rdr',
					isURL: true,
				},
				{
					text: { en: 'YouTube', fr: 'Facebook' },
					link: 'https://www.youtube.com/@sotmafrica',
					isURL: true,
				},
			],
		},
		{
			title: {
				en: 'Contact us',
				fr: 'Contactez nous',
			},
			isActive: true,
			links: [
				{
					text: { en: 'Email', fr: 'Email' },
					link: 'contact@stateofthemap.africa',
					isURL: false,
					isEmail: true,
				},
				{
					text: { en: 'Wiki page', fr: 'Wiki page' },
					link: 'https://wiki.openstreetmap.org/wiki/State_of_the_Map_Africa_2023',
					isURL: true,
				},
				{
					text: {
						en: 'OSM Africa',
						fr: 'OSM Afrique',
					},
					link: '',
					isURL: false,
				},
				{
					text: {
						en: 'Code of conduct',
						fr: 'Code de conduite',
					},
					link: '/code-of-conduct',
					isURL: false,
				},
			],
		},
	],
	footerLogoURL: '/',
	copyright: {
		en: '© 2023 Technical and Communications Committee · SotM Africa',
		fr: '© 2023 Commission technique et de la communication - SotM Afrique',
	},

	// Pages

	//About
	About: {
		title: {
			en: 'About SotM Africa',
			fr: 'A propos de SotM Afrique',
		},
		picture: participants,
	},

	//Sponsors
	Sponsor: {
		title: {
			en: 'SotM Africa Sponsorship',
			fr: 'Sponsoring du SotM Afrique',
		},
		picture: participants,
	},

	//Code of Conduct

	CodeOfConduct: {
		title: {
			en: 'SotM Africa Code of Conduct',
			fr: 'Code de conduite de SotM Afrique',
		},
		picture: participants,
	},
	//Programme
	Programme: {
		title: {
			en: 'SotM Africa 2023 Programme',
			fr: 'Programme SotM Afrique 2023',
		},
		picture: participants,
	},
	// Calls

	Calls: {
		title: {
			en: 'Call for Participation',
			fr: "Appel à manifestation d'intérêt",
		},
		picture: participants,
	},

	//Venue
	Venue: {
		title: {
			en: 'Explore Yaounde',
			fr: 'Visiter Yaounde',
		},
		picture: yaoundeCover,
	},

	//Blog

	blogs: {
		fr: [
			{
				title:
					"La conférence sur l'etat de la carte 2023 se tiendra à Yaoundé au Cameroun",
				date: '22 Oct 2022',
				image: yaoundeCover,
				route: '/blog/sotm-venue-announcement',
				preview: `En Décembre 2023, la communauté OSM Afrique se réunira pour une autre conférence passionnante : State of the Map Africa 2023. Cette conférence a pour but de rassembler les passionnés d'OpenStreetMap d'Afrique et d'ailleurs...`,
			},
		],
		en: [
			{
				title: 'State of the Map Africa 2023 will be held in Yaoundé Cameroon',
				date: 'Oct 22, 2022',
				image: yaoundeCover,
				route: '/blog/sotm-venue-announcement',
				preview: `From 30th November to 2nd December 2023, the OSM Africa community will be coming together for yet another exciting conference: State of the Map Africa, 2023. The conference aims to bring together OpenStreetMap enthusiasts from Africa and beyond...`,
			},
		],
	},

	//page contents

	contentfilePathMapping: {
		en: {
			about: AboutMdEn,
			sponsor: SponsorEn,
			calls: CallsEn,
			'code-of-conduct': CodeOfConductEn,
			venue: VenueEn,
			'sotm-venue-announcement': Blog2023VenueAnnouncement,
		},
		fr: {
			about: AboutMdFr,
			sponsor: SponsorFr,
			calls: CallsFr,
			'code-of-conduct': CodeOfConductFr,
			venue: VenueFr,
			'sotm-venue-announcement': Blog2023VenueAnnouncementFr,
		},
	},
};
