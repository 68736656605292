import './style.css';
import Ticket from './PlanCard';
import { siteConfiguration } from 'siteConfig';
import { useLanguageContext } from 'context/LanguageProvider';

const Pricing = () => {
	const { ticketTitle, earlyBirdEndDate, conferenceTickets } =
		siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<section className="ticket-section">
			<div className="ticket-content">
				<h2>{ticketTitle[currentLanguage]}</h2>
				<p>{earlyBirdEndDate[currentLanguage]}</p>
			</div>
			<div className="pricingTable">
				{conferenceTickets.map((ticket, id) => (
					<Ticket
						key={id}
						ticketName={ticket.ticketName[currentLanguage]}
						ticketUSDPrice={ticket.ticketUSDPrice}
						ticketDescription={ticket.ticketDescription[currentLanguage]}
						text={ticket.ticketBtnText[currentLanguage]}
						route={ticket.ticketLink}
					/>
				))}
			</div>
		</section>
	);
};

export default Pricing;
