import Header from 'components/Header/Header';
import MarkdownLoader from 'components/MarkdownLoader';
import PageHeader from 'components/PageHeader/PageHeader';
import { useLanguageContext } from 'context/LanguageProvider';
import SEO from 'pages/SEO';

import React from 'react';
import { siteConfiguration } from 'siteConfig';

const VenuePage = () => {
	const { Venue } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<>
			<SEO
				title={'Explore'}
				url={'/venue'}
				description={
					'The State of the Map Africa (SotM Africa) is a bi-annual regional conference that celebrates the culture of open mapping, open data, GIS and its impact across Africa.'
				}
			/>
			<Header />
			<div className="aboutpage-section">
				<PageHeader
					title={Venue.title[currentLanguage]}
					picture={Venue.picture}
				/>
				<MarkdownLoader page={'venue'} />
			</div>
		</>
	);
};

export default VenuePage;
