import { ReactComponent as Dropdown } from "assets/dropdown.svg";
import { ReactComponent as DropdownWhite } from "assets/dropdownWhite.svg";
import { siteConfiguration } from "siteConfig";
import "./style.css";
import { useState } from "react";
import { useLanguageContext } from "context/LanguageProvider";
const Language = ({ whiteColor, activateScrollStyling }) => {
  const { Languages } = siteConfiguration;
  const { setCurrentLangauge, currentLanguage } = useLanguageContext();
  const [showDropDown, setShowDropDown] = useState(false);

  // Changes website language
  const ChangeLanguage = (language) => {
    setCurrentLangauge(language);
    setShowDropDown(false);
  };

  return (
    <div
      className="language-section"
      onClick={() => setShowDropDown(!showDropDown)}
    >
      <p
        className={` ${
          whiteColor && activateScrollStyling === false
            ? "activate-white"
            : null
        } language-text`}
      >
        {currentLanguage.toUpperCase()}
      </p>
	  <div className="dropdown">
		{whiteColor && activateScrollStyling === false ? (
			<DropdownWhite className={`${showDropDown && "drop"}`} />
		) : (
			<Dropdown className={`${showDropDown && "drop"}`} />
		)}
	  </div>
	  <div className="dropdown-mobile">
		{whiteColor && activateScrollStyling === false ? (
			<Dropdown className={`${showDropDown && "drop"}`} />
		) : (
			<DropdownWhite className={`${showDropDown && "drop"}`} />
		)}
	  </div>
      
      {showDropDown ? (
        <ul className="language-dropdown">
          {Languages.map((language, id) => {
            return (
              <li
                onClick={() => ChangeLanguage(language.symbol)}
                key={`language-${id}`}
              >
                {language.name}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default Language;
