import React from 'react';
import { Helmet } from 'react-helmet-async';
import OgImage from 'assets/opengraph-image.jpg';

const SEO = ({ title, url, description }) => {
	const title_ = `SOTM Africa 2023 | ${title}`;
	return (
		<Helmet>
			<title>{title_}</title>
			<meta name="title" content={title_}></meta>
			<meta name="description" content={description}></meta>
			<meta
				name="keywords"
				content="SOTM Africa, OSMAfrica, OSM Africa, Africa GIS, Cameroon GIS, Conference, OSM community, State of the Map Africa"
			/>

			<meta property="og:type" content="website"></meta>
			<meta
				property="og:url"
				content={`https://2023.stateofthemap.africa${url}`}
			></meta>
			<meta property="og:title" content={title_}></meta>
			<meta property="og:description" content={description}></meta>
			<meta property="og:image" content={OgImage}></meta>
			<meta property="og:image:width" content="400" />
			<meta property="og:image:height" content="630" />

			<meta name="twitter:card" content="summary"></meta>
			<meta name="twitter:site" content="@sotmafrica"></meta>
			<meta name="twitter:title" content={title_}></meta>
			<meta name="twitter:creator" content="@sotmafrica" />
			<meta
				name="twitter:image:alt"
				content="The State of the Map Africa (SotM Africa) is a bi-annual regional conference that celebrates the culture of open mapping, open data, GIS and its impact across Africa."
			/>
			<meta
				property="twitter:description"
				content="The State of the Map Africa (SotM Africa) is a bi-annual regional conference that celebrates the culture of open mapping, open data, GIS and its impact across Africa."
			></meta>
			<meta property="twitter:image" content={OgImage}></meta>

			{/*Global site tag (gtag.js) - Google Analytics*/}

			<script
				async
				src="https://www.googletagmanager.com/gtag/js?id=G-J26268833P"
			></script>
			<script>
				{`window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', 'G-J26268833P');`}
			</script>
		</Helmet>
	);
};

export default SEO;
