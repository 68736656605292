import { useLanguageContext } from 'context/LanguageProvider';
import { siteConfiguration } from 'siteConfig';
import BlogCard from './BlogCard';
import './style.css';
export default function BlogList() {
	const { currentLanguage } = useLanguageContext();
	const { blogs } = siteConfiguration;
	return (
		<div className="blog-list wrapper">
			{blogs[currentLanguage].map((blog, index) => (
				<BlogCard blog={blog} key={`blog-card-${index}`} />
			))}
		</div>
	);
}
