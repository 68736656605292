import Footer from 'components/Footer/Footer';
import Subscribe from 'components/Subscribe/Subscribe';
import WebsiteMarketing from 'components/WebsiteMarketing/WebsiteMarketing';
import LanguageProvider from 'context/LanguageProvider';
import { HelmetProvider } from 'react-helmet-async';

import About from 'pages/About/About';
import Blog from 'pages/Blog/Blog';
import BlogDetails from 'pages/Blog/BlogDetails';
import Calls from 'pages/Calls/Calls';
import CodeOfConduct from 'pages/CodeOfConduct/CodeOfConduct';
import HomePage from 'pages/HomePage/HomePage';
import NotFound from 'pages/NotFound/NotFound';
import Programme from 'pages/Programme/Programme';
import SponsorShipPage from 'pages/Sponsor/Sponsor';
import VenuePage from 'pages/Venue/Venue';
import { Routes, Route } from 'react-router-dom';

function App() {
	const helmetContext = {};

	return (
		<LanguageProvider>
			<HelmetProvider context={helmetContext}>
				<div className="relative ">
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/about" element={<About />} />
						<Route path="/programme" element={<Programme />} />
						<Route path="/calls" element={<Calls />} />
						<Route path="/sponsor" element={<SponsorShipPage />} />

						<Route path="/blog" element={<Blog />} />
						<Route path="/blog/:blogSlug" element={<BlogDetails />} />
						<Route path="/code-of-conduct" element={<CodeOfConduct />} />
						<Route path="/venue" element={<VenuePage />} />
						<Route path="*" element={<NotFound />} />
					</Routes>

					<WebsiteMarketing />
					<Subscribe />
					<Footer />
				</div>
			</HelmetProvider>
		</LanguageProvider>
	);
}

export default App;
