import React from 'react';
import './style.css';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {
	MapContainer,
	TileLayer,
	Marker,
	Popup,
	ZoomControl,
} from 'react-leaflet';
import { LatLng } from 'leaflet';
import { siteConfiguration } from 'siteConfig';
import locationIcon from '../../assets/marker-icon.png';
import arrowYellow from '../../assets/arrow-yellow.svg';

const Map = () => {
	const { Map } = siteConfiguration;

	const center = new LatLng(...Map.LatLng);
	const myIcon = L.icon({
		iconUrl: locationIcon,
	});
	const zoomControlOptions = {
		position: 'bottomright', // Change this to your desired position: topleft, topright, bottomleft, or bottomright
		fontFamily: 'Kanit, sans-serif',
	};

	return (
		<MapContainer
			zoomControl={false}
			center={center}
			zoom={14}
			scrollWheelZoom={false}
			tapHold={false}
			touchZoom={false}
			dragging={false}
			style={{ height: '400px', fontFamily: 'Kanit, sans-serif', zIndex: 0 }}
		>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			<ZoomControl position={zoomControlOptions.position} />
			<Marker position={Map.LatLng} icon={myIcon}>
				<Popup>
					<div className="popup-container">
						<div className="location-text">
							<h6>{Map.location}</h6>
							<a href={Map.route}>
								<button className="location-button">
									<span className="location-button-text">
										Explore {Map.city}
									</span>
									<img src={arrowYellow} alt="arrow" />
								</button>
							</a>
						</div>
					</div>
				</Popup>
			</Marker>
		</MapContainer>
	);
};

export default Map;
