import Header from 'components/Header/Header';
import PageHeader from 'components/PageHeader/PageHeader';
import React from 'react';
import { siteConfiguration } from 'siteConfig';
import './style.css';
import MarkdownLoader from 'components/MarkdownLoader';
import { useLanguageContext } from 'context/LanguageProvider';
import SEO from 'pages/SEO';
const Calls = () => {
	const { Calls } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<>
			<SEO title={'Call for Participation'} url={'/calls'} />
			<Header />
			<div className="wrapper calls-container">
				<PageHeader
					title={Calls.title[currentLanguage]}
					picture={Calls.picture}
				/>
				<MarkdownLoader page={'calls'} />
			</div>
		</>
	);
};

export default Calls;
