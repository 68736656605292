import { siteConfiguration } from 'siteConfig';
import { NavLink } from 'react-router-dom';
import './style.css';
import { useLanguageContext } from 'context/LanguageProvider';

const Navbar = ({ closeNavBar, whiteColor, scrolledStyling }) => {
	const { navigationLinks } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<nav className="nav-container">
			<ul className="navlinks-container">
				{navigationLinks.map((navigationLink, id) => {
					return navigationLink.isActive ? (
						<NavLink
							to={navigationLink.route}
							onClick={closeNavBar}
							key={`nav-${id}`}
							className={({ isActive: isNavActive }) =>
								isNavActive
									? 'navlink-active'
									: `navlink ${
											whiteColor && scrolledStyling === false
												? 'white-active'
												: null
									  }`
							}
						>
							{navigationLink.text[currentLanguage]}
						</NavLink>
					) : null;
				})}
			</ul>
		</nav>
	);
};

export default Navbar;
