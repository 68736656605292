const { createContext, useContext, useState, useMemo } = require('react');

const LangaugeContext = createContext();
export const useLanguageContext = () => useContext(LangaugeContext);
const LanguageProvider = ({ children }) => {
	const [currentLanguage, setCurrentLangauge] = useState('en');

	const memoized = useMemo(() => {
		return { currentLanguage, setCurrentLangauge };
	}, [currentLanguage, setCurrentLangauge]);
	return (
		<LangaugeContext.Provider value={memoized}>
			{children}
		</LangaugeContext.Provider>
	);
};

export default LanguageProvider;
