import Header from 'components/Header/Header';
import React from 'react';
import SEO from 'pages/SEO';
import BlogList from 'components/Blog/BlogList';
import PageHeader from 'components/PageHeader/PageHeader';

const Blog = () => {
	return (
		<>
			<SEO title={'Blog'} url={'/blog'} />

			<Header />
			<PageHeader title="Blog" />
			<BlogList />
		</>
	);
};

export default Blog;
