import Header from 'components/Header/Header';
import './style.css'
import PageHeader from 'components/PageHeader/PageHeader';
import { useLanguageContext } from 'context/LanguageProvider';
import SEO from 'pages/SEO';
import React from 'react';
import { siteConfiguration } from 'siteConfig';

const Programme = () => {
	const { Programme } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	

	return (
		<>
			<SEO title={'Programme'} url={'/programme'} />
			<Header />
			<div className="aboutpage-section programme">
				<PageHeader
					title={Programme.title[currentLanguage]}
					picture={Programme.picture}
				/>

			
				<pretalx-schedule event-url="https://pretalx.com/sotm-africa-2023/" locale={currentLanguage}></pretalx-schedule>
				
				<noscript>
					<div class="pretalx-widget">
						<div class="pretalx-widget-info-message">
							JavaScript is disabled in your browser. To access our schedule without JavaScript,
							please <a target="_blank" href="https://pretalx.com/sotm-africa-2023/schedule/" rel="noreferrer">click here</a>.
						</div>
					</div>
				</noscript>
			</div>
		</>
	);
};

export default Programme;
