import { useLanguageContext } from 'context/LanguageProvider';

const FooterWidget = (props) => {
	const { currentLanguage } = useLanguageContext();
	return (
		<div className="footer-widget">
			<h3>{props.widgetTitle}</h3>
			<ul className="links">
				{props.WidgetList.map((item, index) => {
					return (
						<li key={index}>
							{/** URLs are links that are not within the page E.g social media.  */}
							<a
								href={item?.isEmail ? `mailto:${item.link}` : item.link}
								target={`${item.isURL ? '_blank' : ''}`}
							>
								{item.text[currentLanguage]}
							</a>
						</li>
					);
				})}
			</ul>
		</div>
	);
};
export default FooterWidget;
