import './style.css';
import RedirectIcon from 'components/Icons/RedirectIcon';
import { useNavigate } from 'react-router-dom';

const Button = ({ text, route, showArrow, arrowColor, isURL = false }) => {
	const navigate = useNavigate();
	return (
		<>
			{isURL ? (
				<a
					href={route}
					target="_blank"
					rel="noreferrer"
					className="button-link"
				>
					<button type="button">
						{text}
						{showArrow ? <RedirectIcon fillColor={arrowColor} /> : null}
					</button>
				</a>
			) : (
				<button
					className="button"
					type="button"
					onClick={() => navigate(route, {})}
				>
					{text}
					{showArrow ? <RedirectIcon fillColor={arrowColor} /> : null}
				</button>
			)}
		</>
	);
};

export default Button;
