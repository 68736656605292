import { siteConfiguration } from 'siteConfig';
import Button from '../Button/Button';
import './Subscribe.css';
import { useLanguageContext } from 'context/LanguageProvider';

const Subscribe = () => {
	const { Subscribe } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<div className="subscribe">
			<div className="subscribe-container">
				<h2>{Subscribe.info[currentLanguage]}</h2>
				<div className="subscribe-button-container">
					<Button
						showArrow={true}
						text={Subscribe.btnName[currentLanguage]}
						route={Subscribe.link}
						isURL={true}
						arrowColor="white"
					/>
				</div>
			</div>
		</div>
	);
};

export default Subscribe;
