import React from 'react';
import './style.css';
import { siteConfiguration } from 'siteConfig';
import { useLanguageContext } from 'context/LanguageProvider';

const Sponsors = () => {
	const { AllSponsor, sponsorProspectusText } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<section className="sponsor-section">
			<div>
				<h2 className="sponsor-section-title">Sponsors</h2>
				<div className="sponsor-packages-wrapper">
					{AllSponsor.map((sponsorType, index) => {
						return (
							<div key={index} className="sponsor-package-container">
								<h3
									style={{
										color: `${sponsorType.color}`,
									}}
								>
									{sponsorType.package[currentLanguage]}
								</h3>
								<ul className="sponsors-logo-container">
									{sponsorType.sponsorsInfo.map((sponsor, index) => {
										return (
											<li
												className="sponsor-logo-wrapper"
												key={`sponsor-type-${index}`}
											>
												{sponsor.logo !== (null || undefined || '') ? (
													<a
														href={sponsor.url}
														target="_blank"
														rel="noreferrer"
													>
														<img
															src={sponsor.logo}
															alt={sponsor.name[currentLanguage]}
															className="sponsor-logo"
															title={sponsor.name[currentLanguage]}
														/>
													</a>
												) : (
													<span>{sponsor.name[currentLanguage]}</span>
												)}
											</li>
										);
									})}
								</ul>
							</div>
						);
					})}
				</div>

				<div className="prospectus-container">
					<p>{sponsorProspectusText[currentLanguage]}</p>
					<a href="/sponsor">Download Sponsorship Prospectus</a>
				</div>
			</div>
		</section>
	);
};

export default Sponsors;
