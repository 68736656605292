import Header from 'components/Header/Header';
import './style.css';

import { siteConfiguration } from 'siteConfig';
import React from 'react';

import PageHeader from 'components/PageHeader/PageHeader';
import MarkdownLoader from 'components/MarkdownLoader';
import { useLanguageContext } from 'context/LanguageProvider';
import SEO from 'pages/SEO';

const CodeOfConduct = () => {
	const { CodeOfConduct } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<>
			<SEO title={'Code of Conduct'} url={'/code-of-conduct'} />
			<Header />
			<div className="aboutpage-section">
				<PageHeader
					title={CodeOfConduct.title[currentLanguage]}
					picture={CodeOfConduct.picture}
				/>

				<MarkdownLoader page={'code-of-conduct'} />
			</div>
		</>
	);
};

export default CodeOfConduct;
