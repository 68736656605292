import './style.css';
import Pricing from 'components/Registration/PricingPlan/PricingPlan';
import { useLanguageContext } from 'context/LanguageProvider';
import { siteConfiguration } from 'siteConfig';

const Registration = () => {
	const { registrationTitle, registrationContent } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<section className="registration">
			<div className="registration-content">
				<h2>{registrationTitle[currentLanguage]}</h2>
				<p>{registrationContent[currentLanguage]}</p>
			</div>
			<Pricing />
		</section>
	);
};

export default Registration;
