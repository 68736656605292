import './style.css';

export default function PageHeader({ title, picture }) {
	return (
		<div className="wrapper page-header-container">
			<h3>{title}</h3>
			{picture ? (
				<div className="page-image-wrapper">
					<img src={picture} alt={title} className="image" />
				</div>
			) : null}
		</div>
	);
}
