import { useLanguageContext } from 'context/LanguageProvider';
import './style.css';
import { siteConfiguration } from 'siteConfig';

const WebsiteMarketing = () => {
	const { WebsiteMarketing } = siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<div className="webmarketing-section wrapper">
			<div className="webmarketing-content">
				<div className="webmarketing-text">
					<h2>{WebsiteMarketing.marketingContent[currentLanguage]}</h2>
				</div>

				<div className="image">
					<img
						src={WebsiteMarketing.picture}
						className="webmarketing-image"
						alt="Mask-group"
					/>
				</div>
			</div>
		</div>
	);
};

export default WebsiteMarketing;
