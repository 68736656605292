import Header from 'components/Header/Header';
import { Link } from 'react-router-dom';
import './style.css';
import SEO from 'pages/SEO';

export default function NotFound() {
	return (
		<>
			<SEO title={'Page Not Found'} url={'/'} />
			<Header />
			<div className="notFound">
				            <h1>Oops! You seem to be lost.</h1>
				            <p>Here are some helpful links:</p>
				<div className="links">
					            <Link to="/">Home</Link>
					            <Link to="/calls">Call for Participation</Link>
					            <Link to="/sponsor">Sponsor</Link>
				</div>
			</div>
			        
		</>
	);
}
