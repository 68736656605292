import Button from 'components/Button/Button';
import './style.css';
export default function BlogCard({ blog }) {
	return (
		<div className="blog-card">
			<img src={blog.image} alt={blog.title}></img>
			<h1>{blog.title}</h1>
			<p>{blog.preview}</p>
			<Button
				text="Read more"
				route={blog.route}
				showArrow
				arrowColor="#f0900a"
			/>
		</div>
	);
}
