import { siteConfiguration } from 'siteConfig';
import './style.css';
import Button from '../Button/Button';
import { useLanguageContext } from 'context/LanguageProvider';
const Venue = () => {
	const { venueTitle, venuePhotos, venueContent, venueButton } =
		siteConfiguration;
	const { currentLanguage } = useLanguageContext();
	return (
		<section className="venue-section">
			<div className="venue-content">
				<h2> {venueTitle[currentLanguage]}</h2>
				<p>{venueContent[currentLanguage]}</p>
				<Button
					text={venueButton.text[currentLanguage]}
					route={venueButton.route}
					showArrow={venueButton.showArrowIcon}
					arrowColor={venueButton.arrowColor}
				/>
			</div>
			<div className="venue-photo">
				{venuePhotos.map((photo, id) => (
					<img src={photo.path} alt={photo.title} key={`venue-${id}`} />
				))}
			</div>
		</section>
	);
};

export default Venue;
