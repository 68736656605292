import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// source = https://stackoverflow.com/a/74566476/14876142

export default function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		const body = document.querySelector('#root');
		body.scrollIntoView(
			{
				behavior: 'smooth',
			},
			500
		);
	}, [pathname]);

	return null;
}
